<template>
    <div class="hover-banner">
        Logined AS 
        <span class="cloak_name">{{cloak_user}}</span>
        <a href="/admin/uncloak_user" class="a_uncloak">Uncloak</a>
    </div>
</template>


<style>
.hover-banner {
    position: fixed;
    height: 35px;
    z-index: 9999999999999999999999;
    text-align: center;
    background: #00a65a;
    color: white;
    opacity: 0.8;
}

.hover-banner:hover {
    opacity: 1;
}

.cloak_name {
    font-size: 25px;
    padding: 0px 10px;
}

.a_uncloak {
    color: black;
    border: 1px solid black;
    padding: 3px 10px;
    ;
    background: white;
    border-radius: 15px;
}

.a_uncloak:active {
    background: #BABABA;
    color: black;
    position: relative;
    top: 1px;
}
</style>


<script>
export default {

    props:["cloak_user"],

    methods:{

    },
}
</script>

