<template>
    <div class="password-reset">
      <div class="card">
        <div class="icon">
          🔒
        </div>
        <h2>Update Password</h2>
        <p>Enter new password and then repeat it</p>

        <div class="input-group">
          <label for="newPassword">Old password</label>
          <div class="input-wrapper">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="newPassword"
              v-model="oldPassword"
            />
            <button type="button" @click="togglePasswordVisibility">
              👁
            </button>
          </div>
        </div>
  
        <div class="input-group">
          <label for="newPassword">New password</label>
          <div class="input-wrapper">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="newPassword"
              v-model="newPassword"
            />
            <button type="button" @click="togglePasswordVisibility">
              👁
            </button>
          </div>
        </div>
  
        <div class="input-group">
          <label for="repeatPassword">Repeat Password</label>
          <div class="input-wrapper">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="repeatPassword"
              v-model="repeatPassword"
            />
            <button type="button" @click="togglePasswordVisibility">
              👁
            </button>
          </div>
        </div>
  
        <button
          class="save-btn"
          :disabled="!isValid"
          @click="resetPassword"
        >
          Save
        </button>
      </div>
    </div>
  </template>
  
  <script>

  import "../../../../css/worker/password_reset.css"

  export default {

    props:['user'],
    data() {
      return {
        newPassword: "",
        repeatPassword: "",
        showPassword: false,
        oldPassword: ""
      };
    },
    computed: {
      isValid() {
        return this.newPassword.length >= 6 && this.newPassword === this.repeatPassword;
      },
    },
    methods: {
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      resetPassword() {
        if (this.isValid) {
            axios.post("/worker/update_password", {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword
            }).then(res => {
                let data = res.data
                if (!data.status) {
                    alert('Error. '+ data.msg)
                } else {
                    alert('Successfully Updated')
                    this.$emit('hide-modal');
                }


                // alert('Successfully Updated')

                // this.$refs["edit-details-modal"].hide()

                // this.profile_data_form = data.data.personal_particular
                // this.is_loading = false
            })
        }
      },
    },
  };
  </script>
  

  