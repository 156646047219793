<template>
    <AppLayout>
        <div class="container">

            <CloakUser
                :is_admin="is_admin"
                :cloak_name="cloak_name"
            ></CloakUser>

            <div class="row"  style="margin-bottom: 20px">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">Announcement</div>

                        <div class="card-body">

                            <template v-if="announcements.length > 0">
                                <template v-for="announcement in announcements">
                                    <div class="row">
                                        <div class="col col-xl-8 pt-2">
                                            <a style="text-align: left" class="announcement-title" :href="'/customer/announcement/'+announcement.ID">{{ announcement.Title }}</a>
                                        </div>
                                        <div class="col col-xl-4 pt-2">
                                            <p style="text-align: right" class="announcement-created-at">{{ announcement.created_at }}</p>
                                        </div>
                                    </div>

                                </template>

                            </template>

                            <template v-else>
                                <div class="col col-xl-12">
                                    <p style="text-align: center">No Announcements...</p>
                                </div>
                            </template>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row" style="margin-bottom: 20px">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">Functionality</div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="/customer/service_agreement">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#6ab187;border-color:#568f6e">
                                                <i class="fas fa-4x fa-handshake"></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#568f6e">
                                                Agreement
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="/customer/service_schedule">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#4CB5F5;border-color:#01579B">
                                                <i class="fa fa-4x fa-list-ul"></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#01579B">
                                                Plans
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="/customer/financial">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#D32D41;border-color:#b72737 ">
                                                <i class="fas fa-4x fa-hand-holding-usd"></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#b72737 ">
                                                Financial
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="/customer/customer_profile">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#b3c100;border-color:#919d00 ">
                                                <i class="fa fa-4x fa-user "></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#919d00 ">
                                                Profile
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="https://chungwahcc.org.au/en/" target="_blank">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#ec430f;border-color:#d33a0e ">
                                                <i class="fas fa-4x fa-globe"></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#d33a0e; height: 50px">
                                                ChungWah Website
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="https://www.youtube.com/@ChungWahCommunityAgedCare"  target="_blank">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#FF0000; border-color:#FF0000 ">
                                                <i class="fab fa-4x fa-youtube"></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#FF0000;  height: 50px ">
                                                Youtube Channel
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-md-4 col-6 center mb-3">
                                    <a href="https://www.facebook.com/chungwahcc" target="_blank">
                                        <div class="fn-div">
                                            <div class="fn-icon" style="background:#4267B2; border-color:#3f61a8 ">
                                                <i class="fab fa-4x fa-facebook-square"></i>
                                            </div>
                                            <div class="fn-text" style="background:white;border-color:#3f61a8;  height: 50px ">
                                                Facebook Community
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-sm col-12">
                    <div class="card">
                        <div class="card-header">Next 3 Days Services</div>
                        <div class="card-body">
                            <template v-for="sa in this.service_assignments">
                                <div class="row">
                                    <div class="col col-sm col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col col-sm col-12">
                                                        <h6 class="text-primary" style="font-weight: bold">{{ sa.activity_name }}
                                                            [{{ sa.service_name }}]</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col col-sm col-12">
                                                        <h6>{{ sa.service_date }} </h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col col-sm col-12">
                                                        <h6>{{ time_convert_to_12hours(sa.start_time) }} -
                                                            {{ time_convert_to_12hours(sa.end_time) }} </h6>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col col-sm col-12">
                                                        <p>Support Worker: {{ sa.sw_name }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<style>
    .announcement-title {
        color: #007bff;
    }

    .announcement-created-at {
        font-size: 12px;
        font-style: italic;
    }
</style>


<script lang="ts">

import moment from "moment"
import { time_convert_to_12hours } from '../../utils.js';
import CloakUser from "./Components/CloakUser.vue";
export default {
    components: {CloakUser},

    props: ["service_assignments", "announcements", 'is_admin', 'cloak_name'],

    methods: {
        time_convert_to_12hours,
    },

    created() {
        console.log(this.service_assignments)
    },
}

</script>