<template>

    <div class="document-upload">
        <div class="card">
            <div class="icon">
                🪪
            </div>
            <h2>Document Upload</h2>
            <p>Select the document type and attach a document.</p>

            <div class="input-group">
                <label for="newPassword">Document Type</label>
                <MySelect
                    :items="dropdown.document_list"
                    style="width: 100%;"
                    v-model="form.document_type"
                />
            </div>

            <div class="input-group">
                <label for="newPassword">Document Type</label>
                <b-form-file
                    id="documentFile"
                    size="sm"
                    placeholder="Upload Document ..."
                    drop-placeholder="Drop file here..."
                    @change="onFileChange"
                    style="height: 50px;"
                ></b-form-file>
            </div>

            <button
                class="save-btn"
                :disabled="!isValid"
                @click="uploadDocument"
            >
                Upload
            </button>
        </div>
    </div>
</template>

<script>

import MySelect from "../../../Components/InputComponent/Select.vue";
import "../../../../css/worker/document_upload.css"

export default {

    components: {MySelect},

    props:["dropdown", "user"],


    data() {
        return {
            select_statement_period_items:[],
            form: {
                url: null,
                document_type: null
            }
        }
    },

    computed: {
      isValid() {
        return this.form.document_type !== null && this.form.url !== null;
      },
    },

    methods: {
        
        uploadDocument() {
            this.$swal({
                title: "Confirmation",
                text: "Confirm to upload the document ?",
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    let formData = new FormData()
                    let document_upload = document.querySelector("#documentFile")
                    formData.append("document", document_upload.files[0])
                    formData.append("document_type", this.form.document_type)
                    formData.append("username", this.user.username)
                    
                    this.$store.commit("showLoading")
                    axios
                        .post(
                           "/worker/document_upload",
                            formData,
                            {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            }
                        )
                        .then((res) => {

                            this.$store.commit("hideLoading")
                            let data = res.data

                            if (data.status) {
                                this.$swal(
                                    "Success",
                                    "Successfully sent the document to HR",
                                    "Success"
                                ).then((res) => {
                                    this.form.url = null;
                                    this.form.document_type = null;

                                    this.$emit('hide-modal');

                                })
                            } else {
                                this.$swal(
                                    "Error",
                                    data.msg,
                                    "error"
                                ).then((res) => {
                                    
                                })
                            }

                            // this.showSuccess(
                            //     "Success",
                            //     "Profile Image has been updated"
                            // )

                            // location.reload()
                        })
                }
            })
        },

        onFileChange(e) {
            const file = e.target.files[0]
            this.form.url = URL.createObjectURL(file)
            console.log(this.form.url)
        },
    }
}


</script>