<template>
    <AppLayout>
        <!-- <div class="row">
          <div class="col col-xl-12">
            <b-button style="float: right;" variant="link">
              <i class="fas fa-chevron-left"></i>
            </b-button>
          </div>
        </div> -->
        <div class="row" style="height: 35px; margin-left: 20px;">
            <div class="col col-xl-12">
              <a href="/worker/home" class="back-button" style="color: #F57626; font-size: 16px;"><i class="fas fa-chevron-left"></i> Dashboard</a>
            </div>
          </div>
        <div class="profile-container">
            <div class="profile-header">
                <!-- <button class="back-button" @click="$emit('goBack')">←</button> -->
                
                <div class="profile-image-container">
                  <!-- <img :src="tempProfilePic" alt="Profile Picture" class="profile-image" /> -->
                  <div
                    class="background-container profile-image"
                    :style="profileImage"
                  >

                  </div> 
                </div>
                
                <h2 class="profile-name">{{user.name}}</h2>
                <p class="profile-username">Username: {{user.username}}</p>
                <!-- <button class="edit-button">Edit Profile</button> -->
            </div>
            <div class="profile-details">      
              <table class="table table-sm table-borderless" style="font-size: 14px;">
                <tbody>
                  <tr>
                    <td><strong>Phone No:</strong></td>
                    <td>{{ user.worker.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Mobile No:</strong></td>
                    <td>{{ user.worker.mobile }}</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td>{{ user.worker.email }}</td>
                  </tr>
                  <tr>
                    <td><strong>Address:</strong></td>
                    <td>{{ user.worker.address }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul class="profile-menu">
                <li v-for="item in menuItems" :key="item.label" class="menu-item" @click="openModal(item.modal)">
                    <!-- <span class="menu-icon">
                      {{item.icon}}
                    </span> -->
                    <span class="menu-label"><i :class="item.icon" style="color: #F57626;"></i> {{ item.label }}</span>
                </li>
            </ul>

            <b-modal
                ref="password-change-modal"
                centered 
                hide-footer
                hide-header
                size="m"
                title="Update Password"
            >
              <PasswordReset :user="user" @hide-modal="hideModal('password-change-modal')">

              </PasswordReset>
            </b-modal>

            <b-modal
                ref="logout-modal"
                centered 
                hide-footer
                hide-header
                size="m"
                title="Logout"
            >
              <div class="row">
                <div class="col col-xl-12">
                  <p style="text-align: center; font-size: 30px; color: brown;"><i class="fas fa-sign-out-alt"></i></p>
                </div>
              </div>
            
              <div class="row" style="margin-bottom: 15px;">
                <div class="col col-xl-12">
                  <p class="confirm-logout">Confirm to Logout?</p>
                </div>
              </div>

              <div class="row">
                <div class="col col-xl-6">
                  <b-button class="btn btn-danger" style="width: 100%;" @click="logout"> <i class="fas fa-check"></i> Confirm</b-button>
                </div>
                <div class="col col-xl-6">
                  <b-button class="btn btn-light"  style="width: 100%;" @click="hideModal('logout-modal')"><i class="fas fa-times"></i> Cancel</b-button>
                </div>
              </div>
            </b-modal>

            <b-modal
                ref="document-upload"
                centered 
                hide-footer
                hide-header
                size="m"
                title="Document Upload"
            >
              <DocumentUpload 
                :dropdown="dropdown" 
                :user="user"
                @hide-modal="hideModal('document-upload')"
              >
              </DocumentUpload>

            </b-modal>
        </div>
    </AppLayout>
</template>


<script lang="ts">

import "../../../css/worker/profile.css"
import PasswordReset from "./Components/PasswordReset.vue";
import DocumentUpload from "./Components/DocumentUpload.vue"

export default {
    components: {PasswordReset, DocumentUpload},
    props:["user", "dropdown"],
    data() {
        return {
            // profileImage: 'https://via.placeholder.com/100', // Replace with actual image URL
            menuItems: [
                // { label: 'Document Upload', icon: '🪪', modal: 'document-upload' },
                // { label: 'Update Password', icon: '🔒', modal: 'password-change-modal' },
                // { label: 'Log out', icon: '🚪',  modal: 'logout-modal'},
                
                { label: 'Document Upload', icon: 'fas fa-id-card', modal: 'document-upload' },
                { label: 'Update Password', icon: 'fas fa-key', modal: 'password-change-modal' },
                { label: 'Log out', icon: 'fas fa-sign-out-alt',  modal: 'logout-modal'},


                
            ],
            tempProfilePic: null
        };
    },

    computed: {
      profileImage() {
        return {
          backgroundImage: `url(${this.tempProfilePic})`,
          backgroundSize: 'cover', // Ensures the image covers the container
          backgroundPosition: 'center center', // Centers the image
          height: '100vh', // Set the height of the container
        };
      }
    },
    methods: {

      openModal(modal) {
        this.$refs[modal].show()
      },

      hideModal(modal) {
        this.$refs[modal].hide()
      },

      logout() {
        axios.post("/logout").then(res => {
                location.reload()
        })
      }

    },
    created() {
      console.log(this.user)
      this.tempProfilePic = this.user.photo_url
    }

  
}
</script>

